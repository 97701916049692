/*
 * Main Script File
 * Author: Mario Jahn
 */

import ScrollPadlock from "/node_modules/scroll-padlock/dist/es/scroll-padlock.min.js";
const scrollPadlock = new ScrollPadlock();

import fitty from "/node_modules/fitty/dist/fitty.module.js";

/*####### PHOTOSWIPE LIGHTBOX / SHOP #######*/
import PhotoSwipeLightbox from "/node_modules/photoswipe/dist/photoswipe-lightbox.esm.min.js";
import PhotoSwipe from "/node_modules/photoswipe/dist/photoswipe.esm.min.js";

const lightbox = new PhotoSwipeLightbox({
	gallery: "#splide__gallery",
	children: "a",
	pswpModule: PhotoSwipe,
	padding: { top: 100, bottom: 100, left: 50, right: 50 },
});
lightbox.init();

jQuery(document).ready(function ($) {
	/*####### MENU #######*/
	$(function () {
		$(".main-menu__nav").smartmenus();
		var $mainMenuState = $("#main-menu__state");
		if ($mainMenuState.length) {
			// animate mobile menu
			$mainMenuState.change(function () {
				var $menu = $(".main-menu__nav");
				if (this.checked) {
					$("body").addClass("scroll-padlock-locked");
				} else {
					$("body").removeClass("scroll-padlock-locked");
				}
			});
			// hide mobile menu beforeunload
			$(window).on("beforeunload unload", function () {
				if ($mainMenuState[0].checked) {
					$mainMenuState[0].click();
				}
			});
		}
		$(".main-menu__nav").on("click.smapi", function (e) {
			if (e.namespace === "smapi") {
				$("body").css("overflow-y", "auto");
				$mainMenuState[0].click();
			}
		});
	});

	/*####### SHRINK HEADER #######*/
	/**
	 ** Add class to Site Logo element when it is past the Intro.
	 **/

	function handleHeaderScroll() {
		let debounceTimeout;
		let animationFrameId; // Store the animation frame request ID

		// Get elements upfront
		const siteHeader = document.getElementById("header");
		const siteIntro = document.getElementById("home__intro-txt");

		// Check initial scroll position on page load
		if (document.documentElement.scrollTop !== 0) {
			siteHeader.classList.add("header--scrolled");
		}

		// Cache height values for efficiency
		const headerHeight = siteHeader.offsetHeight;
		const introOffset = siteIntro.offsetTop;
		const logoHeight = $("#site-logo").outerHeight();
		const initialOffset = $("#site-logo").offset().top + logoHeight;

		// Add scroll event listener
		window.addEventListener("scroll", handleScrollDebounced);

		function handleScrollDebounced() {
			clearTimeout(debounceTimeout);
			cancelAnimationFrame(animationFrameId); // Cancel any pending animation frame
			debounceTimeout = setTimeout(() => {
				animationFrameId = window.requestAnimationFrame(handleScroll); // Store new frame request ID
			}, 50); // Debounced delay
		}

		function handleScroll() {
			// Calculate scroll position relative to logo
			const scrollPosition = $("#site-logo").offset().top + logoHeight;

			if (scrollPosition > introOffset) {
				// Add class (removed delay for debouncing)
				$("#header").addClass("header--scrolled");
			} else if (scrollPosition < initialOffset) {
				// Remove the class
				$("#header").removeClass("header--scrolled");
			}
		}
	}

	// Call the function if the "#home" element exists
	if ($("#home").length) {
		handleHeaderScroll();
	}

	/*
	if ($("#home").length) {
		// Setup a timer
		var timeout;
	
		// Get the site header element
		const siteHeader = document.getElementById("header");
	
		// Check if the page is already scrolled on page load
		let scrollTop = document.documentElement.scrollTop;
		if (scrollTop != 0) {
			siteHeader.classList.add("header--scrolled");
		}
	
		// Get the height of the intro element and logo
		const headerHeight = siteHeader.offsetHeight;
		const siteIntro = document.getElementById("home__intro-txt");
		const introOffset = siteIntro.offsetTop;
		const logoHeight = $("#site-logo").outerHeight();
		let initialOffset = $("#site-logo").offset().top;
		initialOffset += logoHeight;
		let calcHeight = introOffset;
	
		// Add an event listener to the window object to listen for scroll events
		window.addEventListener("scroll", () => {
			// If there's a timer, cancel it
			if (timeout) {
				window.cancelAnimationFrame(timeout);
			}
	
			// Setup the new requestAnimationFrame()
			timeout = window.requestAnimationFrame(function () {
				// Get the current scroll position
				let scrollPosition = $("#site-logo").offset().top;
				scrollPosition += logoHeight;
	
				// Check if the scroll position is past the intro element
				if (scrollPosition > calcHeight) {
					// Add the class to the header element
					$("#header")
						.delay(50)
						.queue(function () {
							$(this).addClass("header--scrolled").dequeue();
						});
				} else if (scrollPosition < initialOffset) {
					// Remove the class from the header element
					$("#header").removeClass("header--scrolled");
				}
			});
		});
	}*/

	/*####### FIT TEXT TO CONTAINER #######*/
	fitty(".fitty");

	fitty("#fitty--min-max", {
		minSize: 22,
		maxSize: 300,
	});

	/*####### MOUNT SPLIDE SLIDER #######*/
	function SlideNumber(Splide, Components) {
		const { track } = Components.Elements;

		let elm;
		let arrowElm;

		function mount() {
			elm = document.createElement("div");
			elm.className = "splide__numbers";
			arrowElm = document.querySelector(".splide__arrow--prev");

			arrowElm.parentElement.insertBefore(elm, arrowElm.nextSibling);

			update();
			Splide.on("move", update);
		}

		function update() {
			elm.textContent = `${Splide.index + 1} von ${Splide.length}`;
		}

		return {
			mount,
		};
	}

	if ($(".splide--home").length) {
		new Splide(".splide--home", {
			type: "loop",
			padding: "20%",
			pagination: false,
			breakpoints: {
				1030: {
					padding: "17%",
				},
				768: {
					padding: "10%",
				},
			},
		}).mount({
			SlideNumber,
		});
	}
	if ($(".splide--immo").length) {
		new Splide(".splide--immo", {
			type: "loop",
			pagination: false,
		}).mount({
			SlideNumber,
		});
	}

	/*####### FILTER: noUiSlider RANGESLIDER #######*/
	if ($("#immobilien").length) {
		// CREATE NUMBER FORMATTER
		const euroFormat = new Intl.NumberFormat("de-DE", {
			style: "decimal",
		});

		// CREATE AREA FORMATTER
		const areaFormat = new Intl.NumberFormat("de-DE", {
			style: "decimal",
		});

		// MIETE
		const rentRange = document.getElementById("rent-range");
		const minRentRange = document.getElementById("rent-range__min");
		const maxRentRange = document.getElementById("rent-range__max");
		const minRentRangeVal = document.getElementById("rent-range__min-val");
		const maxRentRangeVal = document.getElementById("rent-range__max-val");

		noUiSlider.create(rentRange, {
			start: [0, 10000],
			step: 10,
			connect: true,
			range: {
				min: [0],
				max: [10000],
			},
		});
		rentRange.noUiSlider.on("update", function (values, handle) {
			var value = values[handle];
			var eurVal;
			if (handle) {
				if (Math.round(value) == "10000") {
					maxRentRange.value = 99900000;
					maxRentRangeVal.innerHTML =
						euroFormat.format(Math.round(value)) + "+ €";
				} else {
					maxRentRange.value = Math.round(value);
					maxRentRangeVal.innerHTML =
						euroFormat.format(Math.round(value)) + " €";
				}
			} else {
				minRentRange.value = Math.round(value);
				minRentRangeVal.innerHTML = euroFormat.format(Math.round(value)) + " €";
			}
		});
		rentRange.noUiSlider.on("end", function () {
			ajaxImmoFilter($("#filter"));
		});

		// KAUFPREIS
		const priceRange = document.getElementById("price-range");
		const minPriceRange = document.getElementById("price-range__min");
		const maxPriceRange = document.getElementById("price-range__max");
		const minPriceRangeVal = document.getElementById("price-range__min-val");
		const maxPriceRangeVal = document.getElementById("price-range__max-val");

		noUiSlider.create(priceRange, {
			start: [0, 10000000],
			step: 1000,
			connect: true,
			range: {
				min: [10000],
				max: [10000000],
			},
		});
		priceRange.noUiSlider.on("update", function (values, handle) {
			var value = values[handle];
			if (handle) {
				if (Math.round(value) == "10000000") {
					maxPriceRange.value = 9990000000;
					maxPriceRangeVal.innerHTML =
						euroFormat.format(Math.round(value)) + "+ €";
				} else {
					maxPriceRange.value = Math.round(value);
					maxPriceRangeVal.innerHTML =
						euroFormat.format(Math.round(value)) + " €";
				}
			} else {
				minPriceRange.value = Math.round(value);
				minPriceRangeVal.innerHTML =
					euroFormat.format(Math.round(value)) + " €";
			}
		});
		priceRange.noUiSlider.on("end", function () {
			ajaxImmoFilter($("#filter"));
		});

		// FLÄCHE
		const areaRange = document.getElementById("area-range");
		const minAreaRange = document.getElementById("area-range__min");
		const maxAreaRange = document.getElementById("area-range__max");
		const minAreaRangeVal = document.getElementById("area-range__min-val");
		const maxAreaRangeVal = document.getElementById("area-range__max-val");

		noUiSlider.create(areaRange, {
			start: [0, 500],
			connect: true,
			range: {
				min: [0],
				max: [500],
			},
		});
		areaRange.noUiSlider.on("update", function (values, handle) {
			var value = values[handle];
			if (handle) {
				if (Math.round(value) == "500") {
					maxAreaRange.value = 99999;
					maxAreaRangeVal.innerHTML =
						areaFormat.format(Math.round(value)) + "+ &#13217;";
				} else {
					maxAreaRange.value = Math.round(value);
					maxAreaRangeVal.innerHTML =
						areaFormat.format(Math.round(value)) + " &#13217;";
				}
			} else {
				minAreaRange.value = Math.round(value);
				minAreaRangeVal.innerHTML =
					areaFormat.format(Math.round(value)) + " &#13217;";
			}
		});
		areaRange.noUiSlider.on("end", function () {
			ajaxImmoFilter($("#filter"));
		});
		$("#filter").on("reset", function (event) {
			areaRange.noUiSlider.reset();
			priceRange.noUiSlider.reset();
			rentRange.noUiSlider.reset();
			$("#filter__range--kauf").removeClass("show");
			$("#filter__range--miete").addClass("show");
		});
	}

	/*####### AJAX IMMO FILTER #######*/
	function ajaxImmoFilter(data) {
		var filter = $("#filter");
		$.ajax({
			url: filter.attr("action"),
			data: data.serialize(), // form data
			type: filter.attr("method"), // POST
			beforeSend: function (xhr) {
				filter.find("#filter__text").addClass("show"); // changing the button label
			},
			success: function (data) {
				filter.find("#filter__text").removeClass("show"); // changing the button label back
				$("#immobilien__container").html(data); // insert data
			},
			error: function (errorThrown) {
				console.log(errorThrown);
			},
		});
		return false;
	}
	$("#filter").on("change", function () {
		ajaxImmoFilter($("#filter"));
	});
	function toggleFormElements(bDisabled) {
		var inputs = document.getElementsByTagName("input");
		for (var i = 0; i < inputs.length; i++) {
			inputs[i].disabled = bDisabled;
		}
		var selects = document.getElementsByTagName("select");
		for (var i = 0; i < selects.length; i++) {
			selects[i].disabled = bDisabled;
		}
		var textareas = document.getElementsByTagName("textarea");
		for (var i = 0; i < textareas.length; i++) {
			textareas[i].disabled = bDisabled;
		}
		var buttons = document.getElementsByTagName("button");
		for (var i = 0; i < buttons.length; i++) {
			buttons[i].disabled = bDisabled;
		}
	}
	$("#filter").on("reset", function (event) {
		var delayInMilliseconds = 100;
		setTimeout(function () {
			ajaxImmoFilter($("#filter"));
		}, delayInMilliseconds);
	});

	// Conditional Range Filter
	function conditionalRangeFilter() {
		var selectedValue = document.querySelector(
			'input[name="mietekauf"]:checked'
		).value;
		if (selectedValue === "Miete") {
			$("#filter__range--miete").addClass("show");
			$("#filter__range--kauf").removeClass("show");
		} else if (selectedValue === "Kauf") {
			$("#filter__range--kauf").addClass("show");
			$("#filter__range--miete").removeClass("show");
		}
	}
	$("#marketing-type").on("change", function () {
		conditionalRangeFilter();
	});

	/*####### CONTACT MODAL #######*/
	function modalClose() {
		if (location.hash == "#immobilien__contact-modal") {
			location.hash = "";
		}
		$("body").removeClass("scroll-padlock-locked");
	}
	if ($("#immobilien__contact-modal").length) {
		// Handle ESC key (key code 27)
		document.addEventListener("keyup", function (e) {
			if (e.keyCode == 27) {
				modalClose();
			}
		});

		/*$(".theme-switch").click(function() {
			modalClose();
		});*/

		var modal = document.querySelector("#immobilien__contact-modal");

		// Handle click on the modal container
		modal.addEventListener("click", modalClose, false);

		// Prevent event bubbling if click occurred within modal content body
		modal.children[0].addEventListener(
			"click",
			function (e) {
				e.stopPropagation();
			},
			false
		);

		// Disable scrollbars when modal is open
		$("#immobilien__contact-btn").click(function (e) {
			$("body").addClass("scroll-padlock-locked");
		});

		// Enable scrollbars when modal is open
		var modalCloseBtn = document.querySelector(
			"#immobilien__contact-modal__close"
		);
		modalCloseBtn.addEventListener("click", modalClose, false);
	}
}); /* end of as page load scripts */
